import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { ApolloClient, from, createHttpLink, InMemoryCache, ApolloProvider, ApolloConsumer } from '@apollo/client'
import { onError } from "@apollo/client/link/error"
import { setContext } from '@apollo/client/link/context'
import firebase from 'firebase/app'
import 'firebase/auth'

import { NotificationContainer, NotificationManager } from 'react-notifications'
import 'react-notifications/lib/notifications.css'

import Main from 'pages/Main'

const firebaseConfig = {
  apiKey: "AIzaSyDGietmHUCyQk-zC2PJY_dlCu-QR2wc7Ww",
  authDomain: "whack-a-mole-679cc.firebaseapp.com",
  databaseURL: "https://whack-a-mole-679cc.firebaseio.com",
  projectId: "whack-a-mole-679cc",
  storageBucket: "whack-a-mole-679cc.appspot.com",
  messagingSenderId: "203868288722",
  appId: "1:203868288722:web:d97d4e0b2c46a35c491f0b",
  measurementId: "G-ZF35YWVG78"
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message }) =>
      NotificationManager.error(message, 'Error', 6000)
    )
})

const authLink = setContext(async (_, { headers }) => {
  const authToken = localStorage.getItem('authToken') || ''

  return {
    headers: {
      ...headers,
      authToken,
    }
  }
})

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_SERVER_HOST}/graphql`,
})

const client = new ApolloClient({
  link: from([
    errorLink,
    authLink,
    httpLink,
  ]),
  cache: new InMemoryCache()
})

export function WithApolloClient(Component) {
  return props => {
    return (
      <ApolloConsumer>
        {client => <Component {...props} client={client} />}
      </ApolloConsumer>
    )
  }
}

const theme = {
  background: {
    color: {
      primary: 'black'
    }
  },
  font: {
    color: {
      primary: 'black',
      secondary: 'white'
    },
    family: {
      primary: "'Roboto', sans-serif"
    }
  },
}

const App = () => (
  <ApolloProvider client={client}>
    <ThemeProvider theme={theme}>
      <Wrapper>
        <NotificationContainer/>
        <Router>
          <Switch>
            <Route exact path="/"><Main/></Route>
          </Switch>
        </Router>
      </Wrapper>
    </ThemeProvider>
  </ApolloProvider>
)

export default App

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`