import React, { Component } from 'react'
import styled from 'styled-components'

import Window from 'components/Window'
import Auth from 'components/Auth'
import Game from 'components/Game'
import ScoreBoard from 'components/ScoreBoard'
import Settings from 'components/Settings'

export default class Main extends Component {
  constructor(props) {
    super(props)

    this.state = {
      windowContent: 'Play',
      windowHeight: 20,
      windowOnClose: null
    }
  }

  setWindowContent = windowContent => {
    let windowHeight = 25
    let windowOnClose = null

    if(windowContent === 'Play') windowHeight = 20
    if(windowContent === 'Signup') windowHeight = 25
    if(windowContent === 'Scoreboard') windowHeight = 30
    if(windowContent === 'Settings') windowHeight = 20

    if(windowContent === 'Scoreboard') windowOnClose = () => {
      this.setWindowContent('Game')
    }
    if(windowContent === 'Settings') windowOnClose = () => {
      this.setWindowContent('Game')
    }

    this.setState({windowContent, windowHeight, windowOnClose})
  }

  render() {
    const {
      windowContent,
      windowHeight,
      windowOnClose
    } = this.state

    return (
      <Wrapper>
        <Window
          color='rgba(32, 189, 190, 1)'
          title={windowContent}
          compactedWidth={8}
          compactedHeight={5}
          onClose={windowOnClose}
          expandedWidth={30}
          expandedHeight={windowHeight}
          expandedFullscreen={windowContent === 'Game' ? true : false}
          isMounted={true}
        >
          { (windowContent === 'Signup' || windowContent === 'Play') &&
            <Auth
              windowContent={windowContent}
              setWindowContent={this.setWindowContent}
            />
          }
          { windowContent === 'Game' &&
            <Game setWindowContent={this.setWindowContent}/>
          }
          { windowContent === 'Scoreboard' &&
            <ScoreBoard setWindowContent={this.setWindowContent}/>
          }
          { windowContent === 'Settings' &&
            <Settings setWindowContent={this.setWindowContent}/>
          }
        </Window>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  align-self: center;
  justify-self: center;
`