import React, { Component } from 'react'
import styled from 'styled-components'
import { darken } from 'polished'
import { Link } from "react-router-dom";

export default class Button extends Component {
  render() {
    const { width, height, to, children, textOnly, ...props } = this.props

    return (
      <Wrapper width={width} height={height} textOnly={textOnly} {...props}>
        {
          to ?
            <StyledLink to={to}>
              <Text textOnly={textOnly}>
                {children}
              </Text>
            </StyledLink>
          :
            <Text textOnly={textOnly}>
              {children}
            </Text>
        }
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  
  width: ${props => props.width}rem;
  height: ${props => props.height}rem;

  border-radius: ${props => props.textOnly ? 'inherit' : '0.4rem'};


  background: ${props => props.textOnly ? 'inherit' : props.theme.background.color.primary};

  :hover {
    background: ${props => props.textOnly ? 'inherit' : darken(0.1, props.theme.background.color.primary)};
  }

  transition: all 0.2s ease;
  cursor: pointer;
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const Text = styled.span`
  display: flex;

  color: ${props => props.textOnly ? props.theme.font.color.primary : props.theme.font.color.secondary};
  font-family: ${props => props.theme.font.family.primary};
`