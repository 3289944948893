import React, { Component } from 'react'
import {
  Container,
  Content,
  Header,
  HeaderTitle,
  HeaderButtons,
} from './components'
import CircleButton from '../inputs/buttons/CircleButton'

export default class Window extends Component {
  constructor(props) {
    super(props)

    this.state = {
      visible: false,
      active: false,
      locked: false,
    }
  }


  componentDidMount() {
    // Needs a small time delay to ensure smooth transition between screens, and an even longer delay for defaulting to expanded
    if (this.props.isMounted) {
      setTimeout(this.setState({visible: true}), 50)
    }
    if (this.props.active){
      setTimeout(this.setState({active: true}), 550)
    }
  }

  componentDidUpdate(prevProps) {
    // Needs a small time delay to ensure smooth transition between screens, and an even longer delay for defaulting to expanded
    if (this.props.isMounted !== prevProps.isMounted) {
      if (this.state.visible && !this.props.isMounted){
        this.setState({
          visible: false,
          active: false
        })
      } else if (!this.state.visible && this.props.isMounted) {
        setTimeout(this.setState({visible: true}), 50)
      }
    }
    if (this.props.active !== prevProps.active) {
      if (this.props.active){
        setTimeout(this.setState({active: true}), 550)
      }
    }
  }

  render() {
    const {
      active: notUsed,
      title,
      color,
      children,
      onClose,
      expandedFullscreen,
      ...otherProps
    } = this.props

    const { visible, active, locked } = this.state

    return (
      <Container
        windowTitle={title}
        color={color}
        onClick={() => !active && !locked && this.setState({active: true})}
        active={active}
        visible={visible}
        expandedFullscreen={expandedFullscreen}
        {...otherProps}
      >
        { !expandedFullscreen &&
          <Header color={color} active={active}>
            <HeaderButtons active={active}>
              <CircleButton
                onClick={async () => {
                  if (!locked) {
                    let wantToCloseWindow = true
                    if(onClose) wantToCloseWindow = await onClose()

                    if(wantToCloseWindow) {
                      this.setState({
                        active: false,
                        locked: true
                      })
                      setTimeout(() => {
                        this.setState({
                          locked: false
                        })
                      }, 1000)
                    }
                  }
                }}
                background="rgb(255, 59, 48)"
                color="white"
                image="close.svg"
              />
            </HeaderButtons>
            <HeaderTitle>{title}</HeaderTitle>
          </Header>
        }
        <Content active={active} expandedFullscreen={expandedFullscreen}>{children}</Content>
      </Container>
    )
  }
}