import React, { Component } from "react"
import styled from "styled-components"

export default class Button extends Component {
  render() {
    const {
      type = "text",
      label,
      name,
      multiLine = false,
      fullWidth = false,
      value,
      defaultValue,
      color = "black",
      onBlur,
      ...props
    } = this.props

    return (
      <Wrapper
        multiLine={multiLine}
        type={type}
        fullWidth={fullWidth}
        label={label}
      >
        <Field
          {...props}
          type={type}
          multiLine={multiLine}
          name={name}
          color={color}
          value={value}
          defaultValue={defaultValue}
          onBlur={e => {
            if (e.target.value) {
              e.target.nextSibling.classList.add("hasValue")
            } else {
              e.target.nextSibling.classList.remove("hasValue")
            }

            onBlur && onBlur(e)
          }}
        />

        <Label
          color={color}
          className={value || defaultValue ? "hasValue" : ""}
          htmlFor={name}
        >
          {label}
        </Label>
      </Wrapper>
    )
  }
}

const Field = styled(({ multiLine, ...props }) => React.createElement(multiLine ? "textarea" : "input", props))`
  width: 100%;
  font-size: 18px;
  border: medium none;
  border-bottom: 1px solid rgb(224, 224, 224);
  transition: all 0.2s linear;
  background-color: transparent;
  outline: none;
  color: ${({ color }) => color};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "text")};

  &:focus {
    border-bottom: ${({ color}) => `1px solid ${color}`};
  }

  &:focus + label {
    color: ${({ color}) => color};
    font-size: 0.8rem;
    top: 0px;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus &:-webkit-autofill {
    -webkit-text-fill-color: white;
    box-shadow: 0 0 0px 1000px rgba(200, 200, 200, 0.4) inset;
    -webkit-box-shadow: 0 0 0px 1000px rgba(200, 200, 200, 0.4) inset;
    transition: background-color 5000s ease-in-out 0s;
  }
`

const Label = styled.label`
  top: 18px;
  left: 0px;
  font-family: "Lato", sans-serif;
  font-size: 16px;
  position: absolute;
  transition: all 0.2s linear;
  color: ${({ color }) => color};
  pointer-events: none;

  &.hasValue {
    color: ${({ color }) => color};
    font-size: 0.8rem;
    top: 0px;
  }
`

const Wrapper = styled.div`
  width: ${props => (props.fullWidth ? "100%" : "256px")};
  display: ${props => (props.type === "hidden" ? "none" : "flex")};
  flex-direction: column;
  position: relative;
  padding-top: ${props => (props.label ? "1rem" : "0")};
`
