import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  position: relative;
  width: ${props =>
    props.active
      ? props.expandedFullscreen
        ? '100%'
        : props.expandedWidth
          ? `${props.expandedWidth}rem`
          : '10rem'
      : props.compactedWidth
        ? `${props.compactedWidth}rem`
        : '5rem'};
  height: ${props =>
    props.active
    ? props.expandedFullscreen
        ? '100%'
        : props.expandedHeight
          ? `${props.expandedHeight}rem`
          : '10rem'
      : props.compactedHeight
        ? `${props.compactedHeight}rem`
        : '5rem'};
  flex-direction: column;

  @media (max-width: 700px) {
    width: ${props => (props.active ? '100%' : '90%')};
    height: ${props => (props.active ? '100%' : '30%')};
  }

  background: ${props => (props.color ? props.color : '#a0a0a0')};
  border-radius: ${props => (props.expandedFullscreen ? '0' : '8px')};
  

  opacity: ${props => (props.visible ? '1' : '0')};
  box-shadow: -16px 16px 2px 1px
    ${props =>
      props.color
        ? `${props.color.slice(0, -2)}.2)`
        : 'rgba(204, 204, 204, .2)'};

  transition: all 0.5s ease ${props => (props.active ? '0s' : '0.5s')},
    opacity 1s ease ${props => !props.visible && '1s'};

  ${props =>
    !props.active &&
    `animation: float-${
      props.windowTitle ? props.windowTitle.replace(/\s+/g, '-') : 'grey'
    } 4s infinite; cursor: pointer`};

  @keyframes float-${props => props.windowTitle ? props.windowTitle.replace(/\s+/g, '-') : 'grey'} {
    0% {
      box-shadow: 5px 5px 15px
        ${props =>
          props.color
            ? `${props.color.slice(0, -2)}.5)`
            : 'rgba(204, 204, 204, .5)'};
      transform: translateX(-5px) translateY(-5px);
    }
    25% {
      box-shadow: 5px -5px 15px ${props =>
        props.color
          ? `${props.color.slice(0, -2)}.5)`
          : 'rgba(204, 204, 204, .5)'};
      transform: translateX(-5px) translateY(5px);
    }
    50% {
      box-shadow: -5px -5px 15px ${props =>
        props.color
          ? `${props.color.slice(0, -2)}.5)`
          : 'rgba(204, 204, 204, .5)'};
      transform: translateX(5px) translateY(5px);
    }
    75% {
      box-shadow: -5px 5px 15px
        ${props =>
          props.color
            ? `${props.color.slice(0, -2)}.5)`
            : 'rgba(204, 204, 204, .5)'};
      transform: translateX(5px) translateY(-5px);
    }
    100% {
      box-shadow: 5px 5px 15px
        ${props =>
          props.color
            ? `${props.color.slice(0, -2)}.5)`
            : 'rgb(204, 204, 204, .5)'};
      transform: translateX(-5px) translateY(-5px);
    }
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  padding: 0.5rem;

  background: ${props => (props.color ? props.color : '#a0a0a0')};
  border-radius: 8px 8px 0 0;
`

export const HeaderTitle = styled.span`
  display: flex;
  align-items: center;
  color: white;
  height: 2rem;
  font-size: 1.5rem;

  overflow: hidden;
  white-space: nowrap;
`

export const HeaderButtons = styled.div`
  display: flex;
  align-items: center;

  opacity: ${props => (props.active ? '1' : '0')};
  transition-delay: ${props => (!props.active ? '0s' : '0.5s')};

  ${props => !props.active && 'pointer-events: none;'};
`

export const Content = styled.div`
  display: flex;
  flex: 1;

  background: white;

  opacity: ${props => (props.active ? '1' : '0')};
  transition: opacity 0.5s ease;
  transition-delay: ${props => (!props.active ? '0s' : '0.5s')};

  margin: ${props => (props.expandedFullscreen ? '0.5rem' : '0 0.5rem 0.5rem 0.5rem')};

  ${props => !props.active && 'pointer-events: none;'};
`
