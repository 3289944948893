import React, { Component } from 'react'
import { Container, Image } from './components'

export default class CircleButton extends Component {
  render() {
    const { image, ...rest } = this.props

    return (
      <Container {...rest}>
        <Image src={image} alt={image} />
      </Container>
    )
  }
}
