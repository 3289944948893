import React, { Component } from 'react'
import styled from 'styled-components'
import { NotificationManager } from 'react-notifications'
import firebase from 'firebase/app'
import { gql } from '@apollo/client'

import Input from 'components/inputs/TextField'
import Button from 'components/inputs/buttons/Button'

import { WithApolloClient } from 'App'

class Auth extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      reEnterPassword: '',
    }
  }

  componentDidMount() {
    window.addEventListener('keypress', this.enterPress)
  }

  componentWillUnmount() {
    window.removeEventListener('keypress', this.enterPress)
  }

  enterPress = e => {
    const key = e.which || e.keyCode
    if (key === 13) {
      if (this.props.isDoingSignup) {
        this.signUp()
      } else {
        this.logIn()
      }
    }
  }

  logIn = () => {
    if (!this.state.email) {
      NotificationManager.error('Email required!', 'Error', 6000)
      return
    }
    if (!this.state.password) {
      NotificationManager.error('Password required!', 'Error', 6000)
      return
    }

    firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password)
    .then(async () => {
      NotificationManager.success('Logging in!', 'Success', 6000)
      
      const authToken = await firebase.auth().currentUser.getIdToken(true)
      localStorage.setItem('authToken', authToken)

      this.props.setWindowContent('Game')
    })
    .catch(error => {
      console.log(error.code, error.message)
      NotificationManager.error(error.message, 'Error', 6000)
    })
  }

  signUp = () => {
    if (!this.state.email) {
      NotificationManager.error('Email required!', 'Error', 6000)
      return
    }
    if (!this.state.password) {
      NotificationManager.error('Password required!', 'Error', 6000)
      return
    }
    if (this.state.password !== this.state.reEnterPassword) {
      NotificationManager.error('Passwords must match!', 'Error', 6000)
      return
    }

    firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password)
    .then(async () => {
      NotificationManager.success('Account Created. Logging in!', 'Success', 6000)

      const authToken = await firebase.auth().currentUser.getIdToken(true)
      localStorage.setItem('authToken', authToken)

      this.props.client.mutate({
        mutation: gql`
          mutation {
            createAccount
          }
        `,
      })
      .catch(() => {})
      .then(() => {
        this.props.setWindowContent('Game')
      })
    })
    .catch(error => {
      console.log(error.code, error.message)
      NotificationManager.error(error.message, 'Error', 6000)
    })
  }

  render() {
    const {
      windowContent,
      setWindowContent
    } = this.props

    const {
      email,
      password,
      reEnterPassword,
    } = this.state

    return (
      <Wrapper>
        <Input
          label='Email'
          name='email'
          type='email'
          value={email}
          onChange={e => this.setState({email: e.target.value})}
          autoFocus
        />
        <Input
          label='Password'
          name='password'
          type='password'
          value={password}
          onChange={e => this.setState({password: e.target.value})}
        />
        {windowContent === 'Signup' &&
          <Input
            label='Re-enterPassword'
            name='verifyPassword'
            type='password'
            value={reEnterPassword}
            onChange={e => this.setState({reEnterPassword: e.target.value})}
          />
        }
        <Space size={1}/>
        <Button
          width='6'
          height='2'
          onClick = {() => windowContent === 'Signup' ? this.signUp() : this.logIn()}
        >
          {windowContent === 'Signup' ? "Sign Up" : "Log In"}
        </Button>
        <Space size={1}/>
        <Button
          textOnly
          width='20'
          height='2'
          onClick = {() => setWindowContent(windowContent === 'Signup' ? 'Play' : 'Signup')}
        >
          {windowContent === 'Signup' ?
            "Already have an account? Log in instead." :
            "Don't have an account? Sign up! (It's free)"}
        </Button>
        {/* <Button
          width='10'
          height='2'
          onClick = {() => this.props.setIsDoingGame(true)}
        >
          Skip Login
        </Button> */}
      </Wrapper>
    )
  }
}

export default WithApolloClient(Auth)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  align-self: center;
  justify-self: center;
`

const Space = styled.div`
  display: flex;
  height: ${props => (props.size ? `${props.size}rem` : '0')};
`
