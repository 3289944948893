import React, { Component } from 'react'
import styled from 'styled-components'
import { lighten, darken } from 'polished'

export default class WhackAMole extends Component {
  render() {
    const {
      time,
      score,
      moles,
      whack
    } = this.props

    return (
      <Wrapper>
        <Back>
          <Title>Whack A Mole</Title>
          <Stats>
            <Stat>
              <span>TIME</span>
              <span>{time}</span>
            </Stat>
            <Stat>
              <span>SCORE</span>
              <span>{score}</span>
            </Stat>
          </Stats>
        </Back>
        <Top>
          <RowContainer>
            <Row>
              <Tunnel>
                <Mole active={moles ? moles[0] : false} onMouseUp={() => whack(0)}/>
              </Tunnel>
              <Tunnel>
                <Mole active={moles ? moles[1] : false} onMouseUp={() => whack(1)}/>
              </Tunnel>
              <Tunnel>
                <Mole active={moles ? moles[2] : false} onMouseUp={() => whack(2)}/>
              </Tunnel>
            </Row>
            <Row>
              <Tunnel>
                <Mole active={moles ? moles[3] : false} onMouseUp={() => whack(3)}/>
              </Tunnel>
              <Tunnel>
                <Mole  active={moles ? moles[4] : false} onMouseUp={() => whack(4)}/>
              </Tunnel>
            </Row>
          </RowContainer>
        </Top>
        <Front/>
      </Wrapper>
    )
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Back = styled.div`
  display: flex;
  width: 25rem;
  height: 15rem;
  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  background: rgba(32, 189, 190, 1);

  border-radius: 8px 8px 0 0;
`

const Title = styled.span`
  display: flex;

  margin-top: 2rem;

  font-size: 2.5rem;

  color: white;
`

const Stats = styled.div`
  display: flex;
  width: 100%;

  align-items: center;
  justify-content: space-around;

  margin: 1rem 0;
`

const Stat = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  padding: 0.5rem;

  border: 1px solid black;
`

const Top = styled.div`
  position: relative;
  display: flex;
  border-bottom: 20rem solid ${lighten(0.1, 'rgba(32, 189, 190, 1)')};
	border-left: 5rem solid transparent;
	border-right: 5rem solid transparent;
	height: 0;
	width: 25rem;

  align-items: center;
  justify-content: center;
`

const RowContainer = styled.div`
  position: absolute;
  top: -3rem;
  display: flex;
  width: 25rem;
  height: 25rem;

  flex-direction: column;

  align-items: center;
  justify-content: center;
`

const Row = styled.div`
  display: flex;

  margin: 2rem 0;
`

const Tunnel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
	overflow: hidden;
	margin: 0 auto;
	width: 5rem;
	height: 6rem;
	border-radius: 50%/25%;

  margin-right: 3rem;

  :last-child {
    margin-right: 0;
  }

  :before {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 5rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #222;
    content: '';
  }
`

const Mole = styled.div`
  position: absolute;
  display: flex;
  width: 3rem;
  height: 6rem;

  bottom: ${props => props.active ? -1 : -6}rem; // -6rem to -1rem
  border-radius: 1rem 1rem 0 0;

  background: brown;

  transition: all 0.5s ease;

  cursor: pointer;
`

const Front = styled.div`
  display: flex;
  width: 35rem;
  height: 5rem;

  background: ${darken(0.1, 'rgba(32, 189, 190, 1)')};
`