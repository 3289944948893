import React, { Component } from 'react'
import styled from 'styled-components'
import { gql } from '@apollo/client'

import { WithApolloClient } from 'App'

class Settings extends Component {
  constructor(props) {
    super(props)

    this.state = {
      moleSpeed: 50
    }
  }

  componentDidMount() {
    this.getMoleSpeed()
  }

  getMoleSpeed = () => {
    this.props.client.query({
      query: gql`
        query User {
          user {
            moleSpeed
          }
        }
      `,
      fetchPolicy: 'network-only'
    })
    .then(results => {
      this.setState({moleSpeed: results.data.user.moleSpeed})
    })
    .catch(() => {})
  }

  updateMoleSpeed = value => {
    this.props.client.mutate({
      mutation: gql`
        mutation {
          updateMoleSpeed(value: ${value})
        }
      `,
    })
    .then(_results => {
      this.getMoleSpeed()
    })
    .catch(() => {})
  }

  render() {
    const {
      moleSpeed
    } = this.state

    return (
      <Wrapper>
        <SubTitle>Mole Speed</SubTitle>
        <p>{moleSpeed}</p>
        <input type="range" min="1" max="100" value={moleSpeed} onChange={e => this.updateMoleSpeed(e.target.value)}/>
      </Wrapper>
    )
  }
}

export default WithApolloClient(Settings)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  align-self: center;
  justify-self: center;
`

const SubTitle = styled.h3`
  margin-bottom: 0.5rem;
`
