import React, { Component } from 'react'
import styled from 'styled-components'
import { gql } from '@apollo/client'

import { WithApolloClient } from 'App'

class ScoreBoard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      personalScores: [],
      highScores: []
    }
  }

  componentDidMount() {
    this.getScoreBoardData()
  }

  getScoreBoardData = () => {
    this.props.client.query({
      query: gql`
        query User {
          user {
            scoreList
            moleSpeed
          }
        }
      `,
      fetchPolicy: 'network-only'
    })
    .then(results => {
      const personalScores = [...results.data.user.scoreList].sort((a, b) => (b-a))
      this.setState({personalScores})
    })
    .catch(() => {})

    this.props.client.query({
      query: gql`
        query HighScoreList {
          highScoreList {
            email
            score
          }
        }
      `,
      fetchPolicy: 'network-only'
    })
    .then(results => {
      const highScores = [...results.data.highScoreList].sort((a, b) => (b.score-a.score))
      this.setState({highScores})
    })
    .catch(() => {})
  }

  render() {
    const {
      personalScores,
      highScores
    } = this.state

    return (
      <Wrapper>
        <SubTitle>Your Best Scores</SubTitle>
        <Table>
          <Header>
            <HeaderRow>
              <HeaderCol width={2}>Score</HeaderCol>
            </HeaderRow>
          </Header>
          <Body>
            {
              personalScores && personalScores.map((value, key) => (
                <Row key={key}>
                  <Col width={2}>{value}</Col>
                </Row>
              ))
            }
          </Body>
        </Table>
        <SubTitle>Highscores</SubTitle>
        <Table>
          <Header>
            <HeaderRow>
              <HeaderCol width={2}>Rank</HeaderCol>
              <HeaderCol width={18}>Email</HeaderCol>
              <HeaderCol width={2}>Score</HeaderCol>
            </HeaderRow>
          </Header>
          <Body>
            {
              highScores && highScores.map((value, key) => (
                <Row key={key+1}>
                  <Col width={2}>{key+1}</Col>
                  <Col width={18}>{value.email}</Col>
                  <Col width={2}>{value.score}</Col>
                </Row>
              ))
            }
          </Body>
        </Table>
      </Wrapper>
    )
  }
}

export default WithApolloClient(ScoreBoard)

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  align-self: center;
  justify-self: center;
`

const SubTitle = styled.h3`
  margin-bottom: 0.5rem;
`

const Table = styled.table`
  table-layout: fixed;
  border-collapse: collapse;

  margin-bottom: 1rem;
`

const Header = styled.thead`
  background: black;
  color:#fff;
`

const HeaderRow = styled.tr`
  display: block;
`

const HeaderCol = styled.th`
  padding: 5px;
  text-align: left;
  width: ${props => props.width}rem;
`

const Body = styled.tbody`
  display:block;
  width: 100%;
  overflow: auto;
  max-height: 8rem;
`

const Row = styled.tr``

const Col = styled.td`
  padding: 2px 5px;
  text-align: left;
  width: ${props => props.width}rem;
`

