import React, { Component } from 'react'
import styled from 'styled-components'
import { NotificationManager } from 'react-notifications'
import { gql } from '@apollo/client'
import firebase from 'firebase/app'

import Button from 'components/inputs/buttons/Button'
import WhackAMole from 'components/WhackAMole'

import { WithApolloClient } from 'App'

class Game extends Component {
  constructor(props) {
    super(props)

    this.state = {
      attemptedGameStart: false,
      time: 30,
      score: 0,
      moles: [ false, false, false, false, false ],
      loop: null,
    }
  }
  
  signOut = () => {
    this.props.client.resetStore()
    firebase.auth().signOut().then(() => {
      NotificationManager.success('Signing out, Bye!', 'Success', 6000)
      localStorage.removeItem('authToken')
      this.props.setWindowContent('Play')
    }).catch(error => {
      console.log(error)
      NotificationManager.error('There was an issue signing out of your account...', 'Error', 6000)
    })
  }

  startGame = () => {
    this.props.client.mutate({
      mutation: gql`
        mutation {
          startGame
        }
      `,
    })
    .catch(() => {})

    const loop = setInterval(() => {
      this.props.client.query({
        query: gql`
          query Game {
            game {
              time
              score
              moles
            }
          }
        `,
        fetchPolicy: 'network-only'
      })
      .then(({ data: { game: { time, score, moles } } }) => {
        this.setState({ time, score, moles })
        if (time <= 0) {
          clearInterval(this.state.loop)
          this.setState({ attemptedGameStart: false, moles: [ false, false, false, false, false ] })
        }
      })
      .catch(() => {})
    }, 100)

    this.setState({ attemptedGameStart: true, loop })
  }

  whack = mole => {
    this.props.client.mutate({
      mutation: gql`
        mutation {
          whack(mole: ${mole})
        }
      `,
    })
    .catch(() => {})

    let newMoles = [...this.state.moles]
    newMoles[mole] = false
    this.setState({ moles: newMoles })
  }

  render() {
    const {
      setWindowContent
    } = this.props

    const {
      attemptedGameStart,
      time,
      score,
      moles
     } = this.state

    return (
      <Wrapper>
        {!attemptedGameStart &&
          <MenuModal>
            <SignoutButton
              width='6'
              height='2'
              onClick={() => this.signOut()}
            >
              Sign Out
            </SignoutButton>
            <Button
              width='8'
              height='4'
              onClick={() => this.startGame()}
            >
              Play
            </Button>
            <Space size={3}/>
            <Button
              width='8'
              height='2'
              onClick={() => setWindowContent('Settings')} 
            >
              Settings
            </Button>
            <Space size={1}/>
            <Button
              width='8'
              height='2'
              onClick={() => setWindowContent('Scoreboard')} 
            >
              Scoreboard
            </Button>
          </MenuModal>
        }
        <WhackAMole time={time} score={score} moles={moles} whack={this.whack}/>
      </Wrapper>
    )
  }
}

export default WithApolloClient(Game)

const Wrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  align-items: center;
  justify-content: center;
`

const SignoutButton = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;

  margin: 1rem;
`

const MenuModal = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  background-color: rgba(255,255,255,0.8);
  z-index: 999;
`

const Space = styled.div`
  display: flex;
  height: ${props => (props.size ? `${props.size}rem` : '0')};
`
