import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  height: 1.6rem;
  width: 1.6rem;

  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background: ${props =>
    props.background ? props.background : 'rgba(0,0,0,0)'};
  color: ${props => (props.color ? props.color : '#a0a0a0')};
  font-size: 1.3rem;

  cursor: pointer;
`

export const Image = styled.img`
  height: 1.4rem;
  width: 1.4rem;

  filter: invert(1);
`
